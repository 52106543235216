:root {
  --primary-main: #90caf9; /* Light blue for primary actions */
  --primary-dark: #62727b; /* Darker blue for hover states */
  --background-default: #121212; /* Very dark grey for main background */
  --background-paper: #424242; /* Dark grey for elements like cards and menus */
  --text-primary: #ffffff; /* White text for better readability on dark backgrounds */
  --error-main: #f44336; /* Red for errors and warnings */
}

html, body {
  background-color: var(--background-default) !important;
  color: var(--text-primary) !important;
}

.MuiDrawer-paper {
  background-color: var(--background-paper) !important;
}

.MuiButton-root {
  background-color: var(--primary-main) !important;
  color: var(--text-primary) !important;
}

.MuiButton-root:hover {
  background-color: var(--primary-dark) !important;
}

.MuiTextField-root label {
  color: var(--text-primary) !important;
}

.MuiTextField-root input {
  color: var(--text-primary) !important;
}
